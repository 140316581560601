function NotFound() {
    return (
        <div className="d-flex justify-content-center mb-5 text-center bg-white ">
            <div className="d-flex mb-5 flex-column text-center w-50">
                <h1>404</h1>
                <p>Désolé, la page que vous recherchez n'existe pas</p>
                <h2><i className="lni lni-sad"></i></h2>
            </div>
        </div>
    );
}

export default NotFound;
